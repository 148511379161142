import { LitElement, html, css } from "lit";
import { TWStyles } from "../styles/mimic.css.js";

export class Header extends LitElement {
  static styles = [css``, TWStyles];

  render() {
    return html`
      <header class="color:white font-size:md">
        <div
          class="display:flex flex-direction:row padding:md justify-content:space-between color:#FAF9F6"
        >
          <a class="color:#FAF9F6 text-decoration:none" href="/index.html">
            <div
              class="display:flex flex-direction:row align-items:center gap:5px"
            >
              <img src="/Favicon 64x64.png" width="40" alt="VisualGit Logo" />
              <div
                class="font-weight:xl user-select:none font-size:lg color:gray"
              >
                VisualGit
              </div>
            </div>
          </a>
          <a
            target="_blank"
            class="text-decoration:none color:blue:hover margin-top:md color:#FAF9F6"
            href="https://github.com/MarkJamesHoward/VisualGitCmd"
          >
            <img width="50" src="/github-mark.png" alt="GitHub Cat" />
          </a>
        </div>
      </header>
    `;
  }
}

customElements.define("visualgit-header", Header);
